import { render, staticRenderFns } from "./episodeCard.vue?vue&type=template&id=cda656d2&scoped=true&"
import script from "./episodeCard.vue?vue&type=script&lang=js&"
export * from "./episodeCard.vue?vue&type=script&lang=js&"
import style0 from "./episodeCard.vue?vue&type=style&index=0&id=cda656d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cda656d2",
  null
  
)

export default component.exports